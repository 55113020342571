.slide-tab-panel {

  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif!important;
  .container {
    // width: 50%;
    margin: 0 auto;
  }

  ul li {
    display: inline;
    text-align: center;
  }

  a {
    display: inline-block;
    width: 25%;
    padding: .75rem 0;
    margin: 0;
    text-decoration: none;
    color: #c5a47e;
    font-size: 20px;
  }


  @media screen and (max-width: 768px) {
    a {
      font-size: 15px!important;
    }
  }

  .two.active ~ hr, .two:hover ~ hr {
    margin-left: 25%;
  }

  .three.active ~ hr, .three:hover ~ hr {
    margin-left: 50%;
  }

  .four.active ~ hr, .four:hover ~ hr {
    margin-left: 75%;
  }

  .one:active ~ hr {
    margin-left: 0;
  }

  hr {
    height: .25rem;
    width: 25%;
    margin: 0;
    background: tomato;
    border: none;
    transition: .3s ease-in-out;
  }

  // ul {
  //   position: relative;
  //   width: 27em;
  //   height: 2em;
  //   margin: 100px auto;
  //   padding: 0;
  //   white-space: nowrap;
  // }

  // ul li {
  //   display: inline;
  //   text-align: center;
  // }

  // ul li a {
  //   position: relative;
  //   top: 0;
  //   left: 0;
  //   right: 25em;
  //   bottom: 0;
  //   display: inline-block;
  //   -moz-box-sizing: border-box;
  //   box-sizing: border-box;
  //   padding: .4em .2em;
  //   color: #09C;
  //   text-decoration: none;
  //   text-shadow: 0 1px 0 white;

  //   /*transition*/
  //   -webkit-transition: width .3s,right .3s;
  //   -moz-transition: width .3s,right .3s;
  //   -o-transition: width .3s,right .3s;
  //   transition: width .3s,right .3s;
  // }

  // ul li:nth-child(1) a { width: 2em; }
  // ul li:nth-child(2) a { width: 4em; }
  // ul li:nth-child(3) a { width: 4em; }
  // ul li:nth-child(4) a { width: 12em; }
  // ul li:nth-child(5) a { width: 5em; }

  // ul li:last-child a::after {
  //   content: "";
  //   position: absolute;
  //   right: inherit;
  //   bottom: -3px;
  //   width: inherit;
  //   height: 3px;
  //   background: #ccc;
  //   pointer-events: none;
  //   /*transition*/
  //   -webkit-transition: all .5s ease;
  //   -moz-transition: all .5s ease;
  //   -o-transition: all .5s ease;
  //   transition: all .5s ease;
  // }

  // ul li:nth-child(1) ~ li:last-child a {
  //   right: 25em;
  //   width: 2em;
  // }

  // ul li:nth-child(2):hover ~ li:last-child a {
  //   right: 21em;
  //   width: 4em;
  // }

  // ul li:nth-child(3):hover ~ li:last-child a {
  //   right: 17em;
  //   width: 4em;
  // }

  // ul li:nth-child(4):hover ~ li:last-child a {
  //   right: 5em;
  //   width: 12em;
  // }

  // ul li:nth-child(5):last-child:hover a {
  //   right: 0;
  //   width: 5em;
  // }

  // ul li:hover ~ li:last-child a::after,
  // ul li:last-child:hover a::after { background: #c351fa; }

  // ul li:last-child a {
  //   min-width: 5em;
  //   max-width: 5em;
  // }

  // ul li a:hover,
  // ul li a:focus {
  //   color: #c351fa;
  //   background-color: rgba(255,255,255,.6);

  //   /*transition*/
  //   -webkit-transition: width .3s,right .3s,background-color .3s;
  //   -moz-transition: width .3s,right .3s,background-color .3s;
  //   -o-transition: width .3s,right .3s,background-color .3s;
  //   transition: width .3s,right .3s,background-color .3s;
  // }

  // ul li a:focus { border-bottom: 3px solid #c351fa; }
}